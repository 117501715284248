import { useEffect, useState, createRef, useRef, } from 'react';
import { Col, Modal, Checkbox, Row ,Button , Layout,Select,Typography , Input, Divider,Space,Spin,notification,message, Drawer} from 'antd';
import {  SwapOutlined } from '@ant-design/icons';
import { useAccount,useDisconnect, useConnect,useContractWrite,usePrepareContractWrite} from 'wagmi'
import { Profile } from './profile';
import { CloseOutlined } from  '@ant-design/icons'
import { useNetwork,useSwitchNetwork  } from 'wagmi'
import EnsAbi from './ensAbi.json';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { Alchemy, Network, Utils } from "alchemy-sdk";
import { LoadingOutlined } from '@ant-design/icons';
import { NFTStorage,  Blob } from "nft.storage"
import axios from 'axios';
import "./home.css";
import { useWalletClient } from 'wagmi'


const { createCanvas, loadImage } = require('canvas')
const WIDTH = 500;
const HEIGHT = 500;

const client = new NFTStorage({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGZCZTI1MjAyM0VCOTFhOTJiNTE4ZkY5NDEyNDFjRWUzZTkwODlFQjQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUxNTQ1Mzk2MywibmFtZSI6IkluZmluaXR5Vm9pZE1hcCJ9.mj7AFDSMYowKjKoMpPyTjeZfSUbJ_qLgBgMfI2dgDm4" })


const { Header, Footer, Sider, Content } = Layout;
const { Text, Link } = Typography;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color:"#0A0519"
      
    }}
    spin
  />
);

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '10vh',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: 'transparent',
  };

  const contentStyle = {
    minHeight: '55vh',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    
    };



  const footerStyle = {
    bottom:'0',
    position:'fixed',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
  };

  const config = {
    apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
    network: Network.ETH_GOERLI,
  };
  const alchemy = new Alchemy(config);

function ExchangePage() {

   
    const [showWalletModal, setShowWalletModal] = useState(false);
    const { address, isConnected,  connector: activeConnector, } = useAccount();
    const { connect, connectors, error, isLoading, pendingConnector } =useConnect()
    const { chain } = useNetwork()
    const { chains, pendingChainId, switchNetwork } =
      useSwitchNetwork()
      const { data: walletClient } = useWalletClient()

      const { data } = useWalletClient()

    const [api, contextHolder] = notification.useNotification();
    const [amount, setAmount] = useState(0.0001);

   const [LoadingScreen, setLoadingScreen] = useState(false);
    const { disconnect } = useDisconnect();
    const [terms, setTerms] = useState(false);
    const [name, setName] = useState();
    const[imageFile, setImageFile] = useState();


    useEffect(()=>{
         console.log(address);
         if(address !== undefined){
          setShowWalletModal(false);

         }

    },[isConnected]);

    var web3 = new Web3(window.ethereum)

    const mintContract = new web3.eth.Contract(
      EnsAbi,
      "0x1d2F671Ba46a5A1513135d162Ca1ad4208ACD762"
    )


   /* const mintContract = useContract({
      address: '0x1d2F671Ba46a5A1513135d162Ca1ad4208ACD762',
      abi: EnsAbi,
      signerOrProvider: signer,
  
    })
*/
  
console.log(mintContract);




const { write} = useContractWrite({
  address: '0x580Cf9d81D5acf47E3d3Ea45fA35a8F97640439C',
  abi: EnsAbi,
  functionName: 'register',
  onError(error) {
          
    setLoadingScreen(false);
    
    console.log(error);
    openNotification('bottomLeft');
      console.log('Error', error)
    },
  onSuccess(data) {
      console.log(data.hash)
    
                  
  fetch('https://mint.infinityvoid.io/gameApi/masterinventory', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
  body: JSON.stringify({
  
    data :{
      name:name,
      currentOwner:address.toLowerCase(),
      category:"UName",
      contractAddress:"0x580Cf9d81D5acf47E3d3Ea45fA35a8F97640439C",
      tokenId:"",
      chainId:"1"
  
    }
  
    })
  }).then(()=>{
    window.location.reload(true); 
  
  }).catch(error => {
  console.log(error);
  })
                  
                     setLoadingScreen(false);
    
                 
                      openNotificationComplete('bottomLeft');

        
    
  }

})
  

  const openNotificationBalance = (placement) => {
 
    api.error({
      message: 'Transaction Error',
      description:'Insufficient Fund',
      placement
    });
  };


    const openNotification = (placement,description) => {
 
      api.error({
        message: 'Transaction Error',
        description:description,
        placement
      });
    };

    const openNotificationComplete = (placement) => {
 
      api.success({
        message: 'Transaction Complete',
        description:'',
        placement
      });
    };
 
  

    const connectWallet = async()=>{
      setShowWalletModal(true);
      }
      
      const disconnectWallet = async()=>{
        disconnect();
      
        }


    useEffect(()=>{
      },[isConnected]);



    
    const handleNameChange = (e)=>{

      console.log(e.target.value);

      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");

      var namelength = e.target.value.length;

      let backgroundIm
if (namelength<4){
 backgroundIm = "https://img.freepik.com/free-vector/white-gold-geometric-pattern-background-vector_53876-140726.jpg";
 ctx.fillStyle = 'green';
 ctx.fill();
 ctx.fillRect(0, 0, "500", "500");

}

if (namelength>4){
    backgroundIm = "https://static.vecteezy.com/system/resources/previews/001/984/880/original/abstract-colorful-geometric-overlapping-background-and-texture-free-vector.jpg";
    ctx.fillStyle = 'blue';
    ctx.fill();
    ctx.fillRect(0, 0, "500", "500");

  }

if(namelength === 4){
var grd = ctx.createLinearGradient(0, 0, 500, 0);
grd.addColorStop(0, "red");
grd.addColorStop(1, "white");
ctx.fillStyle = grd;
ctx.fillRect(0, 0, 500, 500);
 }

  ctx.fillStyle = '#ffffff';
  ctx.textAlign = "center";
  ctx.font = "42px Arial";
  ctx.fillText(e.target.value, WIDTH/2, HEIGHT/2);
  

                                                                           
  const dataURL = canvas.toDataURL();
console.log(dataURL);

  fetch(dataURL)
  .then(res => res.blob())
  .then(async(blob) => {
      const file = new File([blob], e.target.value, { type: "image/png" })

      console.log(file);
      setImageFile(file);

  })

      setName(e.target.value);
        }

        
    const termsAndCd=()=>{
      console.log(terms);
      setTerms(current => !current);
    }
       
    const mintDomain = async() => {
      if(isConnected == true ){


      const inventoryData  = await axios.get('https://mint.infinityvoid.io/gameApi/masterinventory/?filter[category]=UName')
      console.log(inventoryData.data.rows);
       var allEns = inventoryData.data.rows;

                 

        if ((await activeConnector.getChainId()) !== 1) {
          if (activeConnector.switchChain) {
            await activeConnector.switchChain(1);
          } else {
            openNotification('bottomLeft',"Select ETH Mainnet");
          }
          }

          if(chain.id == "1"){
            if (terms != true){
              openNotification('bottomLeft', "Accept Our terms & condition");
            }else{
             

             
              if (allEns.filter(e => e.name === name).length > 0) {
                openNotification('bottomLeft', "Name is already taken");
              }else{
                setLoadingScreen(true);

                const cid = await client.storeBlob(imageFile)
                var tokenUrlImage = "https://ipfs.io/ipfs/"+cid;

                console.log(tokenUrlImage);
              
      const metadata = {
        "name": name,
        "description": "The InfinityVoid username " + name + ", which is also the ENS subdomain "+ name+".voidpay.eth.",
        "image": tokenUrlImage,
        "attributes": [
            {
                "trait_type": "stats",
                "value": name.length
            },
        
        ]
    }
     const json = JSON.stringify(metadata, null, 3);
  
     const metaFile = new Blob([json], { type: "application/json" });
     const cidmeta = await client.storeBlob(metaFile)
     const tokenMetaUrl = "https://ipfs.io/ipfs/"+cidmeta;

     console.log(tokenMetaUrl);
    
                let amount1 = amount.toString();
                var NFTprice = ethers.utils.parseUnits(amount1, 18);
                console.log(NFTprice);
              
              
                write({
                  args:[name, address, tokenMetaUrl],
                  from: address,
                  value:NFTprice
                 
                })

              /*  const tx = await mintContract.methods.register(name, address, tokenMetaUrl).send({value:NFTprice}).then (async(result)=>  {

                  console.log(result);
                  checkTransactionStatus(result);
                  
                    }).catch (function (error){
                     setLoadingScreen(false);
    
                      console.log(error);
                      openNotification('bottomLeft');
                  });
       */
              }
     
      
              
            }
          
          }


      }else{
        setShowWalletModal(true);
      }

    }


    const  checkTransactionStatus=async(result)=>{

      setLoadingScreen(true);
      const txReceipt = await alchemy.core.getTransactionReceipt(
        result.hash
       );
       if(txReceipt == null){
        setTimeout(checkTransactionStatus(result), 60);
       }else{
        if(txReceipt.status ==1){
          

  fetch('https://mint.infinityvoid.io/gameApi/masterinventory', {
  method: 'POST',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
  },
body: JSON.stringify({

  data :{
    name:name,
    currentOwner:address.toLowerCase(),
    category:"UName",
    contractAddress:"0x580Cf9d81D5acf47E3d3Ea45fA35a8F97640439C",
    tokenId:"",
    chainId:"1"

  }

  })
}).then(()=>{
  window.location.reload(true); 

}).catch(error => {
console.log(error);
})


          openNotificationComplete('bottomLeft');
          window.location.reload(false);
        }
      }
    }


  return (

<>


<canvas
        id="myCanvas"
        width="500"
        height="500"
        style={{ display:'none' }}
      >
        Your browser does not support the HTML canvas tag.
      </canvas>

{contextHolder}

<Spin style={{marginTop:'15%', marginBottom:'15%'}} spinning={LoadingScreen} indicator={antIcon} delay={14} size={'large'}>

     <Modal 
     
       bodyStyle={{color:'white'}}
       closeIcon={<CloseOutlined style={{color:"#26F8FF"}}/>}
        open={showWalletModal}
        centered
        onOk={() => setShowWalletModal(false)}
        onCancel={() => setShowWalletModal(false)}
        footer={null}
        mask={false}
        zIndex={10}
         >
        <Profile/>
      </Modal>




<Layout style={{background:"transparent"}}>
      <Header style={headerStyle}>

      <Row >
      <Col flex={2} className="navBarLogo">
        <Space.Compact direction='horizontal'>
          
          <img src = "./logoicon.webp" style={{width:"64px"}}/>
<Text style={{fontFamily:"Audiowide", fontSize:"24px",marginLeft:"8px",marginTop:"5px",color:"#fff", fontWeight:"200px"}}>Infinity Void</Text>
</Space.Compact>
      </Col>
      <Col flex={2} className="navBarButton">

        {isConnected == true?(

          <Space direction='horizontal'>
          <Text className="textWallet" style={{color:"#fff"}}>
              {address.substring(0, 4)}....{address.slice(38)} 
          </Text>

<Button  onClick={()=>disconnectWallet()} style={{height:"40px", borderRadius:"20px"}}>Disconnect</Button>
</Space>


        ):(
<Button  onClick={()=>connectWallet()} style={{height:"40px", borderRadius:"20px"}}>Connect</Button>

        )}
      </Col>

    </Row>
      </Header> 
      <Content style={contentStyle}>
     
     <Content className='contentStyleMain'>

     <Content className='contentStyleMainChild'>

     <Text className='textHead'><span style={{color:'#26F8FF'}}>Mint </span> <span style={{color:'#FC92F1'}}>Name </span></Text>     

     <Space direction="vertical" size={"large"}>
    <Input onChange={e=>handleNameChange(e)} addonAfter="voidpay.eth" placeholder="Enter Unique Name" />

    <Text className='textPara'>Price - 0.05 ETH + Gas fee</Text>     

    <Checkbox onChange={termsAndCd}><Text style={{color:"#fff",}}>By clicking on mint domain you agree to <a href ="https://www.infinityvoid.io/terms" target='blank' style={{color:'#26F8FF',textDecoration:'underline',textDecorationColor: '#26F8FF'}}>terms & conditions</a></Text></Checkbox>

    <Button onClick={()=>mintDomain()} type="primary">Mint Now</Button>

     </Space>

        </Content>
        </Content>

      </Content>

      <Divider style={{color:"#fff"}}/>
      
    </Layout>

    </Spin>

</>

  );
}

export default ExchangePage;
