import {  ConfigProvider } from 'antd';
import { Routes, Route} from "react-router-dom";
import EnsMint from "./pages/mintEns";

import { WagmiConfig, createConfig , configureChains } from 'wagmi'
import { createPublicClient, http } from 'viem'

import { polygonMumbai, goerli } from 'wagmi/chains'
import { mainnet, polygon, } from 'wagmi/chains'

import { useConnect,useAccount,useContract,useSigner ,useDisconnect  } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { Buffer } from "buffer";
import { Alchemy, Network } from "alchemy-sdk";
import { AlignLeftOutlined, BorderOutlined, CloseOutlined } from  '@ant-design/icons'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import "./App.css";

/*
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, optimism],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY }), publicProvider()],
)

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, polygon,goerli],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY })],
)
*/


 
 
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, 
    polygon,
    polygonMumbai ,
    goerli
  ],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY }), publicProvider()],
)



const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.ETH_GOERLI,
};
const alchemy = new Alchemy(config);

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;


const connectors= [
new MetaMaskConnector({ chains,   
 options: {
  appName: 'Metamask',
  appSub: 'Using your browser extension',
  imageIcon:'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png'
}, }),

new WalletConnectConnector({
  chains,
  options: {
    projectId: '23cc5d757cd05a82e2e1aeab51c8ef73',
    appName: 'Wallet Connect',
    appSub: 'Using a mobile wallet',
    imageIcon:'https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png'
  },
}),

new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Coinbase Wallet',
    appSub: 'Using your mobile or browser extension',
    imageIcon:'https://cdn6.aptoide.com/imgs/0/b/6/0b619167f601e6355364c4f63f0ca166_icon.png'
  },
})
];

const clientWagmi = createConfig({
  //autoConnect: true,
 autoConnect: false,
  connectors:connectors,
  publicClient,
  webSocketPublicClient,
})


function App() {
  return ( 
    

<ConfigProvider
            theme={{
                token: {
                    fontFamily: 'proxima_nova_rgregular',
                    colorPrimary:'#26F8FF',
                   

               

                },
            }}
        >
          <WagmiConfig config={clientWagmi}>
  <Routes>
  
  
  <Route path="/" element={<EnsMint />}/>


 

  

</Routes>
</WagmiConfig>
  </ConfigProvider>

  )
}

export default App;
